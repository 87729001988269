<template>
  <div>
    <div>
      <div class="bg-gray-100 dark:bg-gray-800">
        <div class="max-w-screen-xl mx-auto p-4">
          <div class="flex items">
            <div class="flex-1">
                <h1 class="text-xl mb-8 font-semibold dark:text-gray-100">
                  <span class="text-gray-900" v-if="this.selected_survey">Survey : {{ this.selected_survey.content.description}}</span>
                </h1>
                <div class="mt-4 mb-4">
                  <button @click="back" class="bg-gray-500 mr-2 hover:bg-gray-600 text-white font-semibold px-4 py-2 rounded-lg">Back</button>
                  <button @click="storeSurvey(true)" class="bg-green-500 hover:bg-gray-600 text-white font-semibold px-4 py-2 rounded-lg">Store</button>
                </div>
                <div class="flex mt-10 items-center space-x-2 p-2" v-if="selected_survey">
                      <ul>
                        <li v-for="item in selected_survey.content.form_items" :key="item.id">
                          <div class="flex flex-col text-xs">
                            <div class="flex flex-row px-4">
                                <label class="mt-2 mb-2">
                                  {{ item.label }}
                                </label>
                              <p v-if="item.options.error" class="text-red-500">
                                {{item.options.error_message}}
                              </p>
                            </div>
                            <div class="flex flex-row">
                              <div v-if="item.type==='Datetime'" class="flex py-4 px-4">
                                <input class="min-w-[280px] max-w-[280px] rounded-lg" @change="storeSurvey(false)" v-model="item.answer" v-if="(item.options).timestampType==='date'" type="date"/>
                                <input class="min-w-[280px] max-w-[280px] rounded-lg" @change="storeSurvey(false)" v-model="item.answer" v-if="(item.options).timestampType==='datetime'" type="datetime-local"/>
                                <input class="min-w-[280px] max-w-[280px] rounded-lg" @change="storeSurvey(false)" v-model="item.answer" v-if="(item.options).timestampType==='time'" type="time"/>
                              </div>
                              <div v-if="item.type==='Text'" class="flex  py-4 px-4">
                              <textarea
                                  class="min-w-[280px] max-w-[280px] rounded-lg"
                                  v-model="item.answer"
                                  @change="storeSurvey(false)"
                                  :rows="(item.options).lines"
                                  :minlength="(item.options).minLength"
                                  :maxlength="(item.options).maxLength"
                              />
                              </div>
                              <div v-if="item.type==='Signature'" class="flex py-4 px-4">
                                <SignatureCard :model-value="item.file" @saveSurvey="storeSurvey()" @update:answer="item.answer='Captured'" @update:extension="extension => handleExtension(extension, item)" @update:files="files => handleFiles(files, item)">

                                </SignatureCard>
                              </div>
                              <div v-if="item.type==='Number'" class="flex  py-4 px-4">
                                <input class="text-xs min-w-[280px] max-w-[280px] rounded-lg" v-maska data-maska="############"
                                       @change="storeSurvey(false)"
                                       v-model="item.answer" type="number"
                                       :min="(item.options).Minimum"
                                       :max="(item.options).Maximum"
                                />
                              </div>
                              <div v-if="item.type==='Currency'" class="flex  py-4 px-4">
                                <input class="text-xs min-w-[280px] max-w-[280px] rounded-lg"  v-maska data-maska="############"
                                       @change="storeSurvey(false)"
                                       v-model="item.answer" type="number"
                                       :min="(item.options).Minimum"
                                       :max="(item.options).Maximum"
                                />
                              </div>
                              <div v-if="item.type==='Choices'" class="flex  py-4 px-4">
                                <select class="min-w-[280px] max-w-[280px] rounded-lg"  @change="storeSurvey(false)" v-model="item.answer" :multiple="(item.options).multiChoice">
                                  <option>
                                    -- Select --
                                  </option>
                                  <option  v-for="choice in (item.options).selectable_options" :key="choice" :value="choice">
                                    {{ choice }}
                                  </option>
                                </select>
                              </div>
                              <div v-if="item.type==='Boolean'" class="flex  py-4 px-4">
                                <select class="min-w-[280px] max-w-[280px] rounded-lg" @change="storeSurvey(false)" v-model="item.answer" :multiple="(item.options).multiChoice">
                                  <option>
                                    -- Select --
                                  </option>
                                  <option>
                                    {{item.options.trueOption}}
                                  </option>
                                  <option>
                                    {{item.options.falseOption}}
                                  </option>
                                  <option>
                                    N/A
                                  </option>
                                </select>
                              </div>
                              <div v-if="item.type==='File'" class="flex  py-4 px-4">
                                <div class="block border-2 rounded-lg bg-white py-4 px-4">
                                  <DropzoneComponent @update:extension="extension => handleExtension(extension, item)" @update:files="files => handleFiles(files, item)" :type="(item.options).fileType"></DropzoneComponent>
                                </div>
                                <div class="w-1/3" v-if="item.file">
                                  <img :src="createBlob(item)">
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                </div>
                <div class="mt-4 mb-4">
                  <div class="mt-4 mb-4">
                    <button @click="back" class="bg-gray-500 mr-2 hover:bg-gray-600 text-white font-semibold px-4 py-2 rounded-lg">Back</button>
                    <button @click="storeSurvey(true)" class="bg-green-500 hover:bg-gray-600 text-white font-semibold px-4 py-2 rounded-lg">Store</button>
                  </div>
                </div>
                <div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>

</template>

<script>
import {useRoute} from "vue-router";
import SignatureCard from "@/components/SignatureCard.vue";
import DropzoneComponent from "@/components/DropzoneComponent.vue";
import { vMaska } from "maska"

export default {
  name: 'App',
  components: {
    DropzoneComponent,
    SignatureCard
  },
  directives: {
    mask: vMaska
  },
  data() {
    return {
        db :null,
        dataURL : null,
        survey_changeable : true,
        selected_survey : null,
        next_id : null,
        surveys : null,
    }
  },
  computed: {
    online: function(){
      return this.$store.getters.online;
    },
    user_id : function(){
      return this.$store.getters.user_id;
    },
    token : function(){
      return this.$store.getters.token;
    }
  },
  mounted() {
    this.initializeDataStore();
  },
  setup(){
    let route = useRoute()
    let survey_id = parseInt(route.params.survey_id);
    return{
      survey_id
    }
  },
  methods: {
     createBlob(item) {
       try{
          if(item.file instanceof Blob){
            return URL.createObjectURL(item.file);
          }
       }catch (e) {
         console.error("Error creating blob: ", e);
       }
    },
    handleFiles(files,item){
      item.file = files;
      item.answer = "Attached";
      this.storeSurvey();
    },
    handleExtension(extension,item){
       console.log("Extension: ", extension)
      item.file_extension = extension;
      this.storeSurvey();
    },
    async initializeDataStore(){
      const request = window.indexedDB.open("SurveyDatabase", 2);
      request.onerror = (event) => {
        console.error("Database error: " + event.target.errorCode);
      };
      request.onsuccess = (event) => {
        this.db = event.target.result; // `this` refers to the Vue instance
        console.log("Database opened successfully");
        this.getSurvey();
      };
      request.onupgradeneeded = (event) => {
        this.db = event.target.result;
        if (!this.db.objectStoreNames.contains("SurveyDataStore")) {
          this.db.createObjectStore("SurveyDataStore", { keyPath: "id" });
          console.log("Survey Object store created");
        }
        if (!this.db.objectStoreNames.contains("AnswersDataStore")) {
          this.db.createObjectStore("AnswersDataStore", { keyPath: "id" });
          console.log("Answers Object store created");
        }
      };
    },
    back(){
      this.$router.push({ name: "my-surveys"});
    },
    async storeSurvey(button = false){
        if(button){
          let answered = true;
          let errors = [];
          let survey = this.selected_survey.content;
          let files = [];
          survey.form_items.forEach((item) => {
            files.push({id: item.id,file: item.file});
          });
          survey.form_items.forEach((item) => {
            if(item.options.required===1){
              if(item.answer === null){
                answered = false;
                item.options.error = true;
                errors.push(item.label);
                item.options.error_message = 'Field is required'
              }
            }
          });
          if(answered === false){
            let text = '';
            errors.forEach((error) => {
              text += "<p>" + error + "</p> ";
            });
            this.$notify({
              title: "Sync Failed",
              text: text +" <p>are</p> required fields!",
              type : 'error',
              duration : 500
            });
            return;
          }
        }
        try {
          // Open a transaction as before
          const dbTransaction = this.db.transaction(["AnswersDataStore"], "readwrite");
          const store = dbTransaction.objectStore("AnswersDataStore");

          // Convert the survey to a string
         let survey = this.selected_survey.content;

         let files = [];
         survey.form_items.forEach((item) => {
           files.push({id: item.id,file: item.file});
        });

         survey.describing_name = this.selected_survey.content.describing_name;
         survey.created_date = this.selected_survey.content.created_date;
         console.log("GOing to store");
        console.log(survey);
         survey = JSON.parse(JSON.stringify(survey));
          survey.form_items.forEach((item) => {
            let attached = false;
            if (item.type === "File" || item.type === "Signature") {
              item.file = files.find((file) => file.id === item.id).file;
              attached = true;
            }
            if (attached === false) {
              item.file = null;
            }
          });
          const item = {
            id: this.survey_id, // Ensure this is unique for add, can be existing for update
            content: survey,
          };
          // Use put to add or update
          store.put(item);
          // Transaction complete handler
          dbTransaction.oncomplete = () => {
            if(button){
              this.$notify({
                title: "Success",
                message: "Survey stored successfully",
                type: "success",
              });
            }
            console.log("Data added/updated in the store successfully");
          };
          // Transaction error handler
          dbTransaction.onerror = (event) => {
            console.error("Transaction error: ", event.target.error);
          };
        } catch (error) {
          console.error("Failed to save survey: ", error);
        }
        if(button){
          this.$router.push({ name: "my-surveys"});
        }
    },
    async getSurvey(){
      console.log("Reading Surveys");
      if (!this.db) {
        console.error("Database is not initialized");
        return;
      }
      const transaction = this.db.transaction(["AnswersDataStore"]);
      const objectStore = transaction.objectStore("AnswersDataStore");
      const request = objectStore.get(parseInt(this.survey_id));
      console.log("Request: ", request);
      request.onerror = (event) => {
        console.error("Could not retrieve data: ", event.target.error);
      };

      request.onsuccess = () => {
        if (request.result) {
          this.selected_survey = request.result;
          console.log("Data retrieved: ", request.result);
          console.log(this.selected_survey);
        } else {
          console.log("No data found with id "+this.survey_id);
        }
      };
    }
  }

}
</script>

<style>
.fixed-buttons {
  position: fixed; /* Fixed relative to the viewport */
  top: 100px; /* Distance from the top of the viewport */
  left: 10px;
  width: 200px;
  height: 50px;
  padding: 10px;
}
</style>


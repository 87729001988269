<template>
<div>

  <Vue3Signature @click="updateState()" ref="signature1" :sigOption="state.option" :w="'275px'" :h="'200px'"
                  :disabled="state.disabled" class="example"></Vue3Signature>
  <button v-if="!modelValue && !state.disabled" class="bg-green-500 hover:bg-gray-600 text-white font-semibold px-4 py-2 rounded-lg" @click.prevent="save('image/jpeg')">
      Confirm
  </button>
  <button v-if="!modelValue && state.disabled" class="bg-green-500 hover:bg-gray-600 text-white font-semibold px-4 py-2 rounded-lg" @click.prevent="save('image/jpeg')">
    Modify
  </button>
  <button v-if="modelValue && !state.disabled" class="bg-green-500 hover:bg-gray-600 text-white font-semibold px-4 py-2 rounded-lg" @click.prevent="save('image/jpeg')">
    Update
  </button>
  <button v-if="modelValue && state.disabled" class="bg-green-500 hover:bg-gray-600 text-white font-semibold px-4 py-2 rounded-lg" @click.prevent="save('image/jpeg')">
    Modify
  </button>
  <button class="bg-red-500 hover:bg-gray-600 text-white font-semibold px-4 py-2 rounded-lg" @click.prevent="clear">Clear</button>
</div>

</template>

<script setup>
import {onMounted, reactive, ref} from 'vue'
import { defineProps } from 'vue';
import { defineEmits } from 'vue';
const emit = defineEmits(['update:extension','update:files','update:answer']);

const props = defineProps({
  item_id: String,
  modelValue: File,
  answer: String
})

const state = reactive({
  count: 0,
  drawn : false,
  option: {
    penColor: "rgb(0, 0, 0)",
    backgroundColor: "rgb(255,255,255)"
  },
  disabled: true
})

const updateState = () => {
  state.drawn = true;
}

const signature1 = ref(null)

const save = () => {
  let customerSignature = signature1.value.save('image/jpeg')
  localStorage.setItem(props.item_id+'_signature_base64', (customerSignature));
  state.disabled = !state.disabled;
  fetch(customerSignature)
      .then(res => res.blob())
      .then(blob => {
        let blob_image = new File([blob], 'signature.jpeg', {type: 'image/jpeg'})
        emit('update:files', blob_image);
        emit('update:answer', "Captured");
        emit('update:extension', 'jpeg');
      });
  fromDataURL(customerSignature);
}


const clear = () => {
  signature1.value.clear()
}

// eslint-disable-next-line no-unused-vars
const fromDataURL = (url) => {
  signature1.value.fromDataURL(url);
}

onMounted(() => {
  const reader = new FileReader();
  console.log("Here is modelValue");
  console.log(props.modelValue);
  console.log("Here is modelValue");
  reader.onload = function(event) {
    // event.target.result contains the data URL representing the file
    const dataURL = event.target.result;
    fromDataURL(dataURL);
  };
  if(props.modelValue instanceof Blob){
    reader.readAsDataURL(props.modelValue);
  }

});

</script>

<style scoped>
.example{
  margin: 0 auto;
}
</style>